const clients = [
    {
        id: 3,
        system: 'FAN',
        module: 'ZNAP_SALES_PLANNING',
        modulePath: 'sales',
        language: 'pt',
        port: 8083,
        theme: 'fan.js',
        env: 'prod'
    }
]

const getClientConfigs = (clientId) => clients.find(client => client.id === clientId)
const configs = getClientConfigs(3)

export default {
    ...configs
}
